import React, { useCallback, useLayoutEffect, useState } from 'react';

import { Button, Divider } from '@mui/material';

import usePersistent from 'hooks/use-persistent';
import AutoCompleteInput from 'main/components/SearchBar/components/AutoCompleteInput';
import BaseInput from 'main/components/SearchBar/components/BaseInput';
import ClearButton from 'main/components/SearchBar/components/ClearButton';
import HotKey from 'main/components/SearchBar/components/HotKey';
import SearchButton from 'main/components/SearchBar/components/SearchButton';
import SearchTypeSelect from 'main/components/SearchBar/components/SearchTypeSelect';
import SubscriptionButton from 'main/components/SearchBar/components/SubscriptionButton';
import { defaultSearchBarValue } from 'main/components/SearchBar/constants';
import { ChangeTrigger, SearchFieldErrors, SearchFieldValue, SearchType } from 'main/components/SearchBar/types';
import isEmpty from 'utils/is-empty';

import useStyles from './styles';

interface Props {
    className?: string;
    value?: SearchFieldValue;
    onChange?: (value: SearchFieldValue, trigger: ChangeTrigger) => void;
    onSubmit?: () => void;
    onInputFieldEnter?: () => void;
    suggestions?: { value: string }[];
    loading?: boolean;
    autoCompleteInputRef?: React.RefObject<HTMLInputElement>;
    compact?: boolean;
}

const SearchField: React.FC<Props> = ({
    value,
    className,
    onChange,
    onInputFieldEnter,
    onSubmit,
    suggestions,
    loading,
    compact,
    autoCompleteInputRef
}) => {
    const { classes, cx } = useStyles({ compact });
    const [internalState, setInternalState] = useState<SearchFieldValue>(value ?? defaultSearchBarValue);
    const [errors, setErrors] = useState<SearchFieldErrors>({});

    const persistentInternalState = usePersistent(internalState);
    const { type, query, software, version } = internalState;

    const checkSearchFieldValue = useCallback(() => {
        const { type, software, version } = persistentInternalState.current;
        const errors: SearchFieldErrors = {};
        if (type === SearchType.product) {
            if (!software?.trim()) {
                errors.software = 'Required';
            }
            if (!version?.trim()) {
                errors.version = 'Required';
            }
        }

        setErrors(errors);
        return isEmpty(errors);
    }, [persistentInternalState]);

    const updateValue = useCallback(
        (
            patch: Partial<SearchFieldValue> | ((source: SearchFieldValue) => SearchFieldValue),
            trigger: ChangeTrigger
        ) => {
            let newValue: SearchFieldValue = defaultSearchBarValue;

            setInternalState((source) => {
                newValue = typeof patch === 'function' ? patch(source) : { ...source, ...patch };
                return newValue;
            });

            // Send updates to subscriber
            onChange?.(newValue, trigger);
        },
        [onChange]
    );

    useLayoutEffect(() => {
        // Check if component is controlled
        if (value === undefined || value === null) {
            return;
        }

        // Sync controlled state with props
        setInternalState((state) => {
            for (const key in value) {
                if (state[key] !== value[key]) {
                    return { ...state, ...value };
                }
            }
            return state;
        });
    }, [value]);

    const handleReset = useCallback(() => {
        updateValue(({ type }) => ({ ...defaultSearchBarValue, type }), ChangeTrigger.select);
        setErrors({});
    }, [updateValue]);

    const handleTypeChange = useCallback(
        (type: SearchType) => {
            updateValue((state) => {
                switch (true) {
                    case state.type === SearchType.elastic && type === SearchType.simple:
                        return { ...defaultSearchBarValue, query: state.query, type };
                    default:
                        return { ...defaultSearchBarValue, type };
                }
            }, ChangeTrigger.select);
            setErrors({});
        },
        [updateValue]
    );

    const handleQueryChange = useCallback((query: string, trigger: ChangeTrigger) => updateValue({ query }, trigger), [
        updateValue
    ]);

    const handleSoftwareChange = useCallback(
        (software: string, trigger: ChangeTrigger) => {
            updateValue({ software }, trigger);
            setErrors(({ software: _, ...errors }) => errors);
        },
        [updateValue]
    );

    const handleVersionChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            updateValue({ version: event.target.value }, ChangeTrigger.input);
            setErrors(({ version: _, ...errors }) => errors);
        },
        [updateValue]
    );

    const handleAutoCompleteKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                autoCompleteInputRef?.current?.blur();
                if (checkSearchFieldValue()) {
                    onInputFieldEnter?.();
                }
            }
        },
        [autoCompleteInputRef, onInputFieldEnter, checkSearchFieldValue]
    );

    const handleSubmit = useCallback(() => {
        if (checkSearchFieldValue()) {
            onSubmit?.();
        }
    }, [checkSearchFieldValue, onSubmit]);

    const canClear = [query, software, version].some((value) => value.trim());

    return (
        <div className={cx(classes.container, className)}>
            <SearchTypeSelect
                classes={{ root: classes.typeFieldRoot, list: classes.typeFieldList, label: classes.typeFieldLabel }}
                value={type}
                onChange={handleTypeChange}
            />
            {compact ? null : <Divider orientation={'vertical'} flexItem className={classes.divider} />}
            {[SearchType.simple, SearchType.elastic].includes(type) && (
                <AutoCompleteInput
                    id={`__${type}_autocomplete_input__`}
                    ref={autoCompleteInputRef}
                    loading={loading}
                    placeholder="Searching through 3M+ vulnerabilities and exploits"
                    classes={{ root: classes.searchFieldRoot, list: classes.searchFieldList }}
                    value={query}
                    onChange={handleQueryChange}
                    onKeyDown={handleAutoCompleteKeyDown}
                    options={suggestions}
                />
            )}
            {type === SearchType.product && (
                <>
                    <AutoCompleteInput
                        id={`__${type}_autocomplete_input__`}
                        ref={autoCompleteInputRef}
                        loading={loading}
                        placeholder="Search by product"
                        classes={{ root: classes.searchFieldRoot, list: classes.searchFieldList }}
                        value={software}
                        onChange={handleSoftwareChange}
                        onKeyDown={handleAutoCompleteKeyDown}
                        options={suggestions}
                        error={errors.software}
                    />
                    {compact ? null : <Divider orientation={'vertical'} flexItem className={classes.divider} />}
                    <BaseInput
                        value={version}
                        onChange={handleVersionChange}
                        onKeyDown={handleAutoCompleteKeyDown}
                        placeholder="Version"
                        className={classes.searchFieldRoot}
                        error={errors.version}
                    />
                </>
            )}
            <div className={classes.buttonGroup}>
                {compact ? (
                    <>
                        {canClear ? (
                            <Button className={classes.button} variant="outlined" fullWidth onClick={handleReset}>
                                {'Clear'}
                            </Button>
                        ) : null}
                        <Button
                            className={classes.button}
                            variant="contained"
                            fullWidth
                            color="primary"
                            onClick={handleSubmit}
                            disabled={typeof onSubmit !== 'function'}
                        >
                            {'Search'}
                        </Button>
                    </>
                ) : (
                    <>
                        <SubscriptionButton />
                        <ClearButton onClick={handleReset} visible={canClear} />
                        <SearchButton onClick={handleSubmit} disabled={typeof onSubmit !== 'function'} />
                        <HotKey />
                    </>
                )}
            </div>
        </div>
    );
};

export default React.memo(SearchField);
